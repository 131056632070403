import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, ListItemButton, Divider } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Data from './Data';
import Dots from './Dots';
import RunDate from './RunDate';
import { RUN_STATE_GROUP } from './RunStates';

const FormListItem = ({form, inclDivider, onItemClick}) => {

  return (<>
  {inclDivider && <Divider component="li" />}
  <ListItemButton onClick={() => onItemClick(form)}>
  <ListItem>
    <ListItemAvatar>
      <Avatar sx={{bgcolor:'#CFDBD5'}}>{form.name.charAt(0)}</Avatar>
    </ListItemAvatar>
    <ListItemText sx={{paddingRight:'20px'}}
          primary={<Box sx={{display:'flex',flexDirection: {xs:'column', sm:'row'}, alignItems:'start', gap:{xs:'4px', sm:'20px'}, textWrap:'nowrap', overflow:'hidden'}}>{form.name}</Box>}
          
        />
  </ListItem>
  </ListItemButton>
  </>)
};

export default React.memo(FormListItem);