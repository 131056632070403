import React, { useState, useEffect, useContext } from 'react';
import {Outlet, useParams, useNavigate, NavLink} from 'react-router-dom';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, LinearProgress, Rating, Link, Paper, ToggleButtonGroup, ToggleButton, Button, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DoneIcon from '@mui/icons-material/Done';
import Data from './Data';
import { AccountContext, UserContext } from './AccountFrame';
import { catchError } from 'rxjs';
import { REVIEW_STATE } from './ReviewStates';
import ReviewForm from './ReviewForm';

const Review = () => {
  const { account } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  const { reviewId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [review, setReview] = useState();
  const allRequiredFields = review ? Object.keys(review.form.fields).filter(fieldKey => review.form.fields[fieldKey].required) : []
  const alreadyResponded = review && (review.state == REVIEW_STATE.RESPONDED);
  console.log("allRequiredFields ", allRequiredFields)
  
  useEffect(() => {
    setLoading(true);
    setError(null);
    const subscription = Data.getInstance().fetchReview({accPath: `Accounts/${account.id}`, reviewId: reviewId})
        .subscribe({
            next: (newReview) => {
              setLoading(false);
              console.log("newReview ", newReview)
              console.log("newReview ", JSON.stringify(newReview))
              if (!newReview) {
                setError("Review not found")
              } else if (newReview.expired) {
                setError(<Typography>This review request has expired. You need to <a href='https://www.gotohuman.com/pricing' target="_blank">upgrade your plan</a> to get more time to respond.</Typography>)
              } else if (!newReview.form) {
                setError("The form of this review request wasn't found. It might no longer exists.")
              } else {
                setReview({...newReview});
              }
            },
            error: (err) => {
              setLoading(false);
              setError('There was an error loading the review form.')
            }
        });

    return () => {
      subscription.unsubscribe();
    };
  }, [account, reviewId]);

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Box sx={{
        width: {xs: '90%', lg: '90%'}, maxWidth: {xs: '800px', lg: '1200px'},
        display: 'flex',
        flexDirection: 'column',
        paddingTop: {xs: '14px', lg: '40px'},
        paddingBottom: '40px',
      }}>
        {loading && <LinearProgress color="secondary" />}
        {error && <Box sx={{color: 'red', padding:'10px', textAlign:'center'}}>{error}</Box>}
        {review && (Object.keys(review).length == 0) && <Box sx={{padding:'20px', textAlign:'center'}}><i>Review request not found</i></Box>}
        {review && review.form && <ReviewForm review={review} />}
      </Box>
    </Box>
  )
};

export default Review;