import React, {useState, useEffect, useContext} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import AgentRun from './AgentRun';

const AgentRunPage = () => {
  const {runDocId} = useParams();

  return (<Box sx={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: {xs: '14px', lg: '40px'},
    paddingBottom: '40px',
    background: '#f1f1f1',
    overflowY: 'auto',
    overflowX: 'hidden',
    '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 8,},'::-webkit-scrollbar': {width: '8px'},
  }}>
    <AgentRun runDocId={runDocId} />
  </Box>)
};

export default AgentRunPage;