// Import FirebaseAuth and firebase.
import React from 'react';
import { useNavigate, useLocation, Navigate, useSearchParams } from 'react-router-dom';
import StyledFirebaseAuth from './StyledFirebaseAuth';
import {getAuth, GoogleAuthProvider, EmailAuthProvider} from 'firebase/auth';
import FirebaseManager from './FirebaseManager';
import styles from './css/Login.module.css';
import { Box, Link, Typography } from '@mui/material';

// FirebaseManager.getInstance().init();


function Login({loginOk}) {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const creatingForm =  (searchParams.get("creatingForm") || "") === 'true';
  const location = useLocation();
  const fromPath = location.state?.fromPath;
  const deployUrl = location.state?.deployUrl;
  if (loginOk) return <Navigate {...{to:"/signedIn", replace: true, ...(fromPath ? { state: { fromPath: fromPath, deployUrl: deployUrl } } : {})}} />
  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        // navigate(`/?from=`+(fromPath ? fromPath : 'none'), fromPath ? {replace: true, state: {fromPath: fromPath}} : {replace: true} );
        return false;
      },
    },
  };
  return (
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', height:'100%', minHeight:'100vh', background:'#F1F1F1'}}>
      <img src="/txtlogo332.png" className={styles.logo} />
      <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', background:'#fafafa', border:'1px solid #3B7080', borderRadius:'8px', py:'60px', marginBottom:'40px', width:'90%', maxWidth: '500px'}}>
        <Typography variant='h4' sx={{fontWeight:'400', marginBottom:'40px', textAlign:'center'}}>{creatingForm ? 'Save your form for free' : 'Get started for free'}</Typography>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={getAuth()}
        />

        <Typography variant="subtitle2" sx={{ color: '#71717a', marginTop:'40px' }} component={Link} href="https://www.gotohuman.com/terms-of-service" target="_blank">You hereby agree to our terms of service.</Typography>
        {/* <div className={styles.terms}>
          <span>
            You hereby agree to our terms of service.
          </span>
        </div> */}
      </Box>
    </Box>
  );
}

export default Login;
