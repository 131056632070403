import React, {useState, useEffect, useContext} from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, LinearProgress, Box, Paper, Button, TextField, Link } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import CircularProgress from '@mui/material/CircularProgress';
import {catchError} from 'rxjs/operators';
import Data from './Data';
import { AccountContext } from './AccountFrame';

const Settings = () => {
  const { account } = useContext(AccountContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [agents, setAgents] = useState([]);
  const [creating, setCreating] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    setError(null);
    const subscription = Data.getInstance().streamAgentConnectors(`Accounts/${account.id}`)
        .pipe(catchError((err) => {
          console.error('streamAgentConnectors err', err)
          setError('There was an error loading the AI agent connectors.');
          setLoading(false);
        }))
        .subscribe(
            (agents) => {
              setLoading(false);
              setAgents([...agents]);
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [account]);

  const handleListItemClick = (runClicked) => {
    console.log("handleListItemClick " + runClicked.docId)
    navigate(`/accounts/${account.id}/runs/${runClicked.docId}`);
  }

  return (<Box sx={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '40px',
    paddingBottom: '20px',
    background: '#f1f1f1',
    overflowY: 'auto',
    overflowX: 'hidden',
    '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 8,},'::-webkit-scrollbar': {width: '8px'},
  }}>
    <Paper sx={{ width: '90%', maxWidth: '1040px' }}>
      {loading && <LinearProgress color="secondary" />}
      {error && <Box sx={{color: 'red', padding:'10px', textAlign:'center'}}>{error}</Box>}
      {!!agents && <Box sx={{ padding:'40px', }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom:'32px', gap:'8px'}}>
          <Typography variant="h4" sx={{ fontWeight: '600' }}>Agent connectors</Typography>
          <Typography variant="subtitle2" sx={{ color: '#71717a' }}>Agent connectors configure your agent. The url is the webhook we call after a human decision and it's where we send the trigger event (if you trigger via gotoHuman).</Typography>
          <Box sx={{ display:'flex', flexDirection:'column', gap:'8px', marginTop: '8px' }}>
            <Typography variant="subtitle2" sx={{ color: '#71717a' }} component={Link} href="https://docs.gotohuman.com" target="_blank">Read our developer documentation</Typography>
            <Typography variant="subtitle2" sx={{ color: '#71717a' }} component={Link} href="https://docs.gotohuman.com/Examples/customer-chatbot" target="_blank">Quick-start templates</Typography>
          </Box>
        </Box>
        {!creating && <Button sx={{marginBottom:'24px'}} variant='contained' color="primary" startIcon={<AddCircleOutlineIcon />} onClick={()=>setCreating(true)}>Create new agent connector</Button>}
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '14px'}}>
        {creating && <Box sx={{}}><AgentCreator accountId={account.id} agentIds={agents.map(ag => ag.id)} onDone={() => {console.log("oNDONE"); setCreating(false);}} /></Box>}
        {agents.map((agent, index) => {
          return <AgentConnector key={agent.id} accountId={account.id} agent={agent} />
        })}
        </Box>
      </Box>}
    </Paper>
  </Box>)
};

export default Settings;

const AgentCreator = ({accountId, agentIds, onDone}) => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();
  const [showVars, setShowVars] = useState(false);
  const [editedAgent, setEditedAgent] = useState({});
  const validData = editedAgent.id && !!editedAgent.id.length && !agentIds.includes(editedAgent.id) && editedAgent.name && !!editedAgent.name.length
  
  useEffect(() => {
    if (!saving || !validData) return () => { };
    setError(null);
    const subscription = Data.getInstance().createAgentConnector(accountId, editedAgent.id, editedAgent.name, undefIfEmpty(editedAgent.webhook), undefIfEmpty(editedAgent.triggerPayloadLabel1), pairOrUndefIfEmpty(editedAgent.configKey1, editedAgent.configVal1), pairOrUndefIfEmpty(editedAgent.configKey2, editedAgent.configVal2), pairOrUndefIfEmpty(editedAgent.configKey3, editedAgent.configVal3))
      .pipe(catchError(() => {setError('There was an error saving your changes.');setSaving(false);}))
      .subscribe((x) => {
        console.log("AGENT created");
        onDone();
        setSaving(false);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, saving]);

  return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', minWidth:'60%', gap: '12px', padding:'10px', background:'#f2f2f2', borderRadius:'8px' }}>
    <TextField sx={{width:'500px', maxWidth:'100%'}} disabled={saving} value={editedAgent.id || ""} label={"Id"} onChange={(event) => setEditedAgent({...editedAgent, id: event.target.value})} />
    <TextField sx={{width:'500px', maxWidth:'100%'}} disabled={saving} value={editedAgent.name || ""} label={"Name"} onChange={(event) => setEditedAgent({...editedAgent, name: event.target.value})} />
    <TextField sx={{width:'100%'}} disabled={saving} value={editedAgent.webhook || ""} label={"Agent/Webhook url"} onChange={(event) => setEditedAgent({...editedAgent, webhook: event.target.value})} />
    <TextField sx={{width:'100%'}} disabled={saving} value={editedAgent.triggerPayloadLabel1 || ""} label={"Input required to trigger run (optional, e.g. 'name')"} onChange={(event) => setEditedAgent({...editedAgent, triggerPayloadLabel1: event.target.value})} />
    <Box sx={{ display: 'flex', alignItems:'center'}}>
      {showVars && <IconButton onClick={() => setShowVars(false)}><ExpandLessOutlinedIcon /></IconButton>}
      {!showVars && <IconButton onClick={() => setShowVars(true)}><ExpandMoreOutlinedIcon /></IconButton>}
      Add additional variables to be passed to agent (optional):
    </Box>
    {showVars && <>
    <TextField sx={{width:'50%'}} disabled={saving} value={editedAgent.configKey1 || ""} label={"Variable 1 name"} onChange={(event) => setEditedAgent({...editedAgent, configKey1: event.target.value})} />
    <TextField sx={{width:'100%'}} disabled={saving} value={editedAgent.configVal1 || ""} label={"Variable 1 value"} onChange={(event) => setEditedAgent({...editedAgent, configVal1: event.target.value})} />
    <TextField sx={{width:'50%'}} disabled={saving} value={editedAgent.configKey2 || ""} label={"Variable 2 name"} onChange={(event) => setEditedAgent({...editedAgent, configKey2: event.target.value})} />
    <TextField sx={{width:'100%'}} disabled={saving} value={editedAgent.configVal2 || ""} label={"Variable 2 value"} onChange={(event) => setEditedAgent({...editedAgent, configVal2: event.target.value})} />
    <TextField sx={{width:'50%'}} disabled={saving} value={editedAgent.configKey3 || ""} label={"Variable 3 name"} onChange={(event) => setEditedAgent({...editedAgent, configKey3: event.target.value})} />
    <TextField sx={{width:'100%'}} disabled={saving} value={editedAgent.configVal3 || ""} label={"Variable 3 value"} onChange={(event) => setEditedAgent({...editedAgent, configVal3: event.target.value})} />
    </>}
    <Button variant="contained" color="success" disabled={!validData || saving} onClick={() => setSaving(true)} startIcon={saving ? <CircularProgress color="success" size={24} /> : <DoneIcon />}>Save</Button>
    <Button variant="outlined" color="error" disabled={saving} onClick={() => {setEditedAgent({}); onDone();}}>Dismiss</Button>
    {error && <Box sx={{color: 'red', padding:'10px'}}>{error}</Box>}
  </Box>
}

const undefIfEmpty = (value) => (typeof value === 'string' && value.length > 0) ? value : undefined;
const pairOrUndefIfEmpty = (value1, value2) => (typeof value1 === 'string' && value1.length > 0 && typeof value2 === 'string' && value2.length > 0) ? [value1, value2] : undefined;

const AgentConnector = ({accountId, agent}) => {
  const [editing, setEditing] = useState(false);
  const [showVars, setShowVars] = useState(agent.configKey1 || agent.configKey2 || agent.configKey3);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();
  const [editedAgent, setEditedAgent] = useState(agent);
  
  useEffect(() => {
    if (!saving) return () => { };
    setError(null);
    const subscription = Data.getInstance().updateAgentConnector(accountId, agent.docId, editedAgent.name, editedAgent.webhook, undefIfEmpty(editedAgent.triggerPayloadLabel1), pairOrUndefIfEmpty(editedAgent.configKey1, editedAgent.configVal1), pairOrUndefIfEmpty(editedAgent.configKey2, editedAgent.configVal2), pairOrUndefIfEmpty(editedAgent.configKey3, editedAgent.configVal3))
      .pipe(catchError(() => {setError('There was an error saving your changes.');setSaving(false);}))
      .subscribe((x) => {
        setEditing(false)
        setSaving(false)
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, saving]);

  return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: editing ? 'start' : 'normal', gap: '12px', padding:'10px', background:'#f2f2f2', borderRadius:'8px' }}>
    <AgentSettingRow name={"Id"} values={[editedAgent.id]} />
    {!editing && <AgentSettingRow name={"Name"} values={[editedAgent.name]} />}
    {editing && <TextField sx={{width:'500px', maxWidth:'100%'}} disabled={saving} value={editedAgent.name} label={"Name"} onChange={(event) => setEditedAgent({...editedAgent, name: event.target.value})} />}
    {agent.freezeWebhook && <Typography sx={{fontStyle: 'italic'}}>Endpoint is pre-defined for this integration</Typography>}
    {!agent.freezeWebhook && !editing && <AgentSettingRow name={"Agent/Webhook url"} values={[editedAgent.webhook]} />}
    {!agent.freezeWebhook && editing && <TextField sx={{width:'100%'}} disabled={saving} value={editedAgent.webhook} label={"Agent/Webhook url"} onChange={(event) => setEditedAgent({...editedAgent, webhook: event.target.value})} />}
    {!editing && <AgentSettingRow name={"Input required to trigger run (optional)"} values={[editedAgent.triggerPayloadLabel1]} />}
    {editing && <TextField sx={{width:'500px', maxWidth:'100%'}} disabled={saving} value={editedAgent.triggerPayloadLabel1} label={"Input required to trigger run (optional, e.g. 'name')"} onChange={(event) => setEditedAgent({...editedAgent, triggerPayloadLabel1: event.target.value})} />}

    {editing && <Box sx={{ display: 'flex', alignItems:'center'}}>
      {showVars && <IconButton onClick={() => setShowVars(false)}><ExpandLessOutlinedIcon /></IconButton>}
      {!showVars && <IconButton onClick={() => setShowVars(true)}><ExpandMoreOutlinedIcon /></IconButton>}
      <Typography typography={"subtitle2"}>Additional variables passed to agent (optional):</Typography>
    </Box>}
    {showVars && <>
    {editing && <TextField sx={{width:'50%'}} disabled={saving} value={editedAgent.configKey1 || ""} label={"Variable 1 name"} onChange={(event) => setEditedAgent({...editedAgent, configKey1: event.target.value})} />}
    {editing && <TextField sx={{width:'100%'}} disabled={saving} value={editedAgent.configVal1 || ""} label={"Variable 1 value"} onChange={(event) => setEditedAgent({...editedAgent, configVal1: event.target.value})} />}
    {editing && <TextField sx={{width:'50%'}} disabled={saving} value={editedAgent.configKey2 || ""} label={"Variable 2 name"} onChange={(event) => setEditedAgent({...editedAgent, configKey2: event.target.value})} />}
    {editing && <TextField sx={{width:'100%'}} disabled={saving} value={editedAgent.configVal2 || ""} label={"Variable 2 value"} onChange={(event) => setEditedAgent({...editedAgent, configVal2: event.target.value})} />}
    {editing && <TextField sx={{width:'50%'}} disabled={saving} value={editedAgent.configKey3 || ""} label={"Variable 3 name"} onChange={(event) => setEditedAgent({...editedAgent, configKey3: event.target.value})} />}
    {editing && <TextField sx={{width:'100%'}} disabled={saving} value={editedAgent.configVal3 || ""} label={"Variable 3 value"} onChange={(event) => setEditedAgent({...editedAgent, configVal3: event.target.value})} />}
    </>}

    {!editing && <AgentSettingRow name={"Additional variables passed to agent (optional)"} values={[...(editedAgent.configKey1 ? [`${editedAgent.configKey1}: ${editedAgent.configVal1}`] : []), ...(editedAgent.configKey1 ? [`${editedAgent.configKey2}: ${editedAgent.configVal2}`] : []), ...(editedAgent.configKey3 ? [`${editedAgent.configKey1}: ${editedAgent.configVal3}`] : [])]} />}
    {/* {!editing && editedAgent.configKey1 && <Box sx={{display:'flex', flexDirection:{xs: 'column', lg: 'row'}, alignItems:'start'}}><Typography sx={{marginLeft:'12px', fontFamily: 'Monospace'}}>{editedAgent.configKey1}:</Typography><Typography sx={{marginLeft:'12px', fontFamily: 'Monospace'}}>{editedAgent.configVal1}</Typography></Box>}
    {!editing && editedAgent.configKey2 && <Box sx={{display:'flex', flexDirection:{xs: 'column', lg: 'row'}, alignItems:'start'}}><Typography sx={{marginLeft:'12px', fontFamily: 'Monospace'}}>{editedAgent.configKey2}:</Typography><Typography sx={{marginLeft:'12px', fontFamily: 'Monospace'}}>{editedAgent.configVal2}</Typography></Box>}
    {!editing && editedAgent.configKey3 && <Box sx={{display:'flex', flexDirection:{xs: 'column', lg: 'row'}, alignItems:'start'}}><Typography sx={{marginLeft:'12px', fontFamily: 'Monospace'}}>{editedAgent.configKey3}:</Typography><Typography sx={{marginLeft:'12px', fontFamily: 'Monospace'}}>{editedAgent.configVal3}</Typography></Box>} */}
    
    {!editing && <Box sx={{ display: 'flex'}}><Button sx={{}} onClick={()=>setEditing(true)}>Edit</Button><DropDownMenu accountId={accountId} agent={agent} /></Box>}
    {editing && <Button variant="contained" color="success" disabled={!editedAgent.name.length || saving} onClick={() => setSaving(true)} startIcon={saving ? <CircularProgress color="success" size={24} /> : <DoneIcon />}>Save</Button>}
    {editing && <Button variant="outlined" color="error" disabled={saving} onClick={() => {setEditing(false); setEditedAgent({...agent})}}>Cancel</Button>}
    {error && <Box sx={{color: 'red', padding:'10px'}}>{error}</Box>}
  </Box>
}
const AgentSettingRow = ({name, values}) => {
  return <Box sx={{display:'flex', flexDirection:{xs: 'column', md: 'row'}, alignItems:'baseline'}}>
    <Typography variant="subtitle2" sx={{flex: '0 0 50%', maxWidth:'300px'}}>{name}</Typography>
    <Box sx={{display:'flex', flexDirection:'column', gap: '6px'}}>
      {values.filter(val=>val && (val.length > 0)).map((value) => <Typography variant="subtitle2" sx={{marginLeft:'12px', background:'#fafafa', borderRadius:'8px', p: '8px'
      }}>{value}</Typography>)}
    </Box>
  </Box>
}

const DropDownMenu = ({accountId, agent}) => {
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setDeleteTrigger(true);
  };
  
  useEffect(() => {
    if (!deleteTrigger) return () => { };
    const subscription = Data.getInstance().updateAgentConnector(accountId, agent.docId, agent.name, agent.webhook, agent.triggerPayloadLabel1, pairOrUndefIfEmpty(agent.configKey1, agent.configVal1), pairOrUndefIfEmpty(agent.configKey2, agent.configVal2), pairOrUndefIfEmpty(agent.configKey3, agent.configVal3), true)
      .subscribe((x) => {
        setDeleteTrigger(false);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, agent, deleteTrigger]);

  return (
    <Box sx={{padding:'0px'}}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
          <MenuItem key={"archive"} onClick={handleDelete}>
            Delete
          </MenuItem>
      </Menu>
    </Box>
  );
}