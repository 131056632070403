import {initializeApp} from 'firebase/app';
import {getAnalytics, logEvent} from 'firebase/analytics';
import { getPerformance } from "firebase/performance";

class FirebaseManager {
  constructor() {
    if (!FirebaseManager.instance) {
      this.initializeFirebase();
      FirebaseManager.instance = this;
    }
    return FirebaseManager.instance;
  }
  
  initializeFirebase() {
    const firebaseConfig = {
      apiKey: "AIzaSyA-_j-VTHjb0cqDZPQdcjUuLpu8NLKQyiI",
      authDomain: "auth.gotohuman.com",
      projectId: "gotohuman",
      storageBucket: "gotohuman.appspot.com",
      messagingSenderId: "396485546793",
      appId: "1:396485546793:web:3e7395fcff5bc9b3e2dd38",
      measurementId: "G-NHFQ1NPXSG"
    };

    const app = initializeApp(firebaseConfig);
    this.perf = getPerformance(app);
    this.analytics = getAnalytics(app);
    window.gtag('config', firebaseConfig.measurementId, {
      send_page_view: false
    });
  }
  
  trackEvent(eventName, eventParams) {
    console.log(`trackEvent eventName ${eventName} eventParams ${JSON.stringify(eventParams||{})}`)
    if (process.env.NODE_ENV !== 'production') return;
    // Log event using Firebase Analytics
    if (this.analytics) {
      logEvent(this.analytics, eventName, eventParams);
    }

    // Log event using gtag
    if (window.gtag) {
      window.gtag("event", eventName, eventParams);
    }
  }

  trackPageview(path) {
    console.log(`trackPageview path ${path} title ${document.title}`)
    if (process.env.NODE_ENV !== 'production') return;
    // Log event using Firebase Analytics
    if (this.analytics) {
      logEvent(this.analytics, 'screen_view', {firebase_screen: path});
    }

    // Log event using gtag
    if (window.gtag) {
      window.gtag("event", 'page_view', {
        // page_title: title,
        page_location:path
      });
    }
  }
}

const instance = new FirebaseManager();
Object.freeze(instance);

export default instance;
