import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, ListItemButton, Divider } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Data from './Data';
import Dots from './Dots';
import RunDate from './RunDate';
import { RUN_STATE_GROUP } from './RunStates';

const InboxItem = ({review, inclDivider, onItemClick, timePing}) => {
  const [input, setInput] = useState("");
  // const millis2daysAgo = (new Date()).getTime() - (2 * 24 * 60 * 60 * 1000)
  // const reviewExpired = (review.createdAt?.toMillis() || 0) < millis2daysAgo
  const reviewExpired = false

  const previewString = (agentRun) => {
    //show action, otherwise last context item
    const preStr = (agentRun.action || ((agentRun.context && agentRun.context.length) ? agentRun.context[agentRun.context.length-1] : ""));
    const shortened = preStr.slice(0,180)
    const toReturn = (shortened.length < preStr.length) ? (shortened + "...") : shortened;
    console.log("previewString " + preStr.length + " shortened: " + shortened.length);
    return toReturn;
  }

  return (<>
  {inclDivider && <Divider component="li" />}
  <ListItemButton onClick={() => reviewExpired ? window.open('https://www.gotohuman.com/pricing', '_blank') : onItemClick(review)}>
  <ListItem>
    <ListItemAvatar>
      <Avatar sx={{bgcolor:'#CFDBD5'}}>{review.form.name.charAt(0)}</Avatar>
    </ListItemAvatar>
    {!reviewExpired && <ListItemText sx={{paddingRight:'16px'}} primary={
      <Box sx={{display:'flex',flexDirection: {xs:'column', sm:'row'}, alignItems:'start', gap:{xs:'4px', sm:'20px'}, textWrap:'nowrap', overflow:'hidden'}}>{review.form.name}</Box>
    }/>}
    {reviewExpired && <ListItemText sx={{paddingRight:'16px'}} primary={
      <Box sx={{display:'flex',flexDirection: {xs:'column', sm:'row'}, alignItems:'start', gap:{xs:'4px', sm:'20px'}, textWrap:'nowrap', overflow:'hidden'}}><Typography sx={{overflow:'hidden'}}>{review.form.name}</Typography><Typography variant='caption' sx={{background:'#E26D5C', color:'#fefefe', borderRadius:'4px', px:'6px', py:'1px'}}>Review expired</Typography></Box>
    }/>}
        {/* {(run.state === RUN_STATE.RUNNING) && <Dots />} */}
    <RunDate timeStampDate={review.createdAt} pingForUpdate={timePing} />
    
  </ListItem>
  </ListItemButton>
  </>)
};

export default React.memo(InboxItem);