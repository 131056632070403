import React, {useState, useEffect, useContext} from 'react';
import { Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, LinearProgress, Box, Paper, Button, Divider, Tooltip, TextField, Tabs, Tab } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import {catchError} from 'rxjs/operators';
import Data from './Data';
import { RUN_STATE, RUN_STATE_GROUP, HUMAN_REVIEW_SUB_STATES, mapRunStateGroup } from './RunStates';
import Dots from './Dots';
import { AccountContext, UserContext } from './AccountFrame';

export const TRIGGER = {IDLE:'idle', APPROVE:'approve', REJECT:'reject', ARCHIVE: 'done'}

const AgentRun = ({runDocId, runId, customMissingRunMsg}) => {
  const { user } = useContext(UserContext);
  const { account } = useContext(AccountContext);
  const accountId = account.id;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [run, setRun] = useState();
  const [selectedChildRun, setSelectedChildRun] = useState(0);
  const [webhooks, setWebhooks] = useState();
  
  useEffect(() => {
    setLoading(true);
    setError(null);
    const subscription = Data.getInstance().streamRun({accountId: accountId, ...(runDocId && {runDocId: runDocId}), ...(runId && {runId: runId})})
        .pipe(catchError(() => setError('There was an error loading the AI agent run.')))
        .subscribe(
            (newRun) => {
              setLoading(false);
              console.log("setting run with x steps:" + newRun.steps?.length)
              setRun({...newRun});
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, runDocId, runId]);
  
  useEffect(() => {
    if (!run || !["com.gotohuman.tests.lyzr.linkedin", "com.gotohuman.tests.email", "com.gotohuman.tests.agent", "com.gotohuman.demos.chatbot"].includes(run.agentId)) {
      setWebhooks(null)
      return;
    }
    const subscription = Data.getInstance().streamRunTriggeredWebhooks(accountId, run.docId)
        .subscribe(
            (hooks) => {
              setWebhooks([...hooks]);
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, run]);

  return (<Box sx={{
    width: {xs: '90%', lg: '90%'}, maxWidth: {xs: '800px', lg: '1200px'},
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0px',
    paddingBottom: '0px',
  }}>
    {loading && <LinearProgress color="secondary" />}
    {error && <Box sx={{color: 'red', padding:'10px', textAlign:'center'}}>{error}</Box>}
    {run && !run.docId && <Box sx={{padding:'20px', textAlign:'center'}}><i>{customMissingRunMsg || 'Run not found'}</i></Box>}
    {run && run.agent && <>
      <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', marginBottom:'20px'}}>
        <Box sx={{flexGrow: 1, paddingLeft:'20px', paddingRight:'20px', paddingTop:'0px'}}>
          <Typography variant="h4" sx={{ fontWeight: '600' }}>{runDocId ? run.agent.name : ""}</Typography>
        </Box>
        <DropDownMenu accountId={accountId} user={user} runDocId={run.docId} />
      </Box>
      <Box sx={{display:'flex', flexDirection:'column', marginBottom:'20px'}}>
        <Typography variant="subtitle2" sx={{color:'#888', fontSize:'12px', paddingLeft:'20px', textAlign:'start'}}>{(run.createdAt || run.lastModified) ? ((new Date((run.createdAt || run.lastModified).seconds * 1000)).toDateString()) : ""}</Typography> {/* lastModified serverTimestamp update temporarily null when human reacts */}
        <Typography variant="h8" sx={{color:'#ccc', fontSize:'12px', paddingLeft:'20px', textAlign:'start'}}>Run: {run.runId}</Typography>
      </Box>
    </>}
    <Box sx={{
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '0px',
    paddingBottom: '0px',
    gap: '40px',
  }}>
    <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    alignItems: 'center',
    paddingTop: '0px',
    paddingBottom: '0px',
  }}>
    {loading && <LinearProgress color="secondary" />}
    {error && <Box sx={{color: 'red', padding:'10px', textAlign:'center'}}>{error}</Box>}
    {run && !run.docId && <Box sx={{padding:'20px', textAlign:'center'}}><i>{customMissingRunMsg || 'Run not found'}</i></Box>}
    {run && run.agent && <>
    
    <Box sx={{ width: '100%', padding:'6px', }}>
      <Timeline sx={{ [`& .${timelineItemClasses.root}:before`]: {flex: 0, padding: 0} }}>
        {run.steps
        .filter((step, index) => (step.state !== RUN_STATE.TASK_RUNNING && step.state !== RUN_STATE.REQUESTED_HUMAN && step.state !== RUN_STATE.SERVED_TO_HUMAN) || (index == (run.steps.length-1))) //only show running and human-approval items when latest item
        .map((step, index, arr) => {
          return <RunStepBox run={run} step={step} isLastItem={index == arr.length - 1} />
        })}
        {run.steps && !!run.steps.length && (run.steps[run.steps.length - 1].state === RUN_STATE.TASK_DONE) && <TimelineItem key="runningDots"><TimelineSeparator><TimelineDot variant="outlined" sx={{width:'20px', height:'20px'}} /></TimelineSeparator><TimelineContent><Dots /></TimelineContent></TimelineItem>}
      </Timeline>
    </Box>
    </>
    }
    { (run && run.steps && (run.steps.length > 0) && run.steps[run.steps.length-1].state === RUN_STATE.APPROVED && run.steps[run.steps.length-1].subState === HUMAN_REVIEW_SUB_STATES.MULTI_SELECT_FAN_OUT) ? (
      <Box sx={{width: '100%', display:'flex', flexDirection:'column', alignItems:'center', px:'0px'}}>
        <Tabs sx={{width: {xs: '100%', lg: '100%'}, maxWidth: {xs: '800px', lg: '1200px'}}} value={selectedChildRun} onChange={(event, newValue) =>setSelectedChildRun(newValue)}>
          {run.steps[run.steps.length-1].actionValues.filter(av=>!!av.childRunId).map((av, index) => (
            <Tab label={`${index}: ${(av.text||'').substring(0,10)}`} key={av.childRunId} />
          )
          )}
        </Tabs>
        <AgentRun account={account} user={user} runId={run.steps[run.steps.length-1].actionValues.filter(av=>!!av.childRunId)[selectedChildRun].childRunId} customMissingRunMsg={"Waiting for run"} />
        {/* <Box>{childruns.find((r,i)=>i == selectedChildRun).docId}</Box> */}
      </Box>) : ""  
    }
    {/* {run && run.childRunIds && (run.childRunIds.length>0) && <Box sx={{display:'flex', flexDirection:'column', width: '90%', maxWidth: '1040px'}}><Tabs value={selectedChildRun} onChange={(event, newValue) =>setSelectedChildRun(newValue)}>
      {run.childRunIds.map((childrunId, index) => <Tab label={`${index}: ${childrunId}`} id={childrunId} />)}
      </Tabs>
      <AgentRun account={account} user={user} runId={run.childRunIds[selectedChildRun]} />
      </Box>
    } */}
    {webhooks && !!webhooks.length && <Paper sx={{ width: '90%', maxWidth: '1040px', background:'#ffe7a8', color:'#888', marginTop:'20px' }}>
      <Box sx={{display:'flex', flexDirection:'column', padding:'20px', gap:'12px'}}>
        <Typography>Debug info</Typography>
        {webhooks.map(hook => <Box key={hook.docId} sx={{display:'flex', flexDirection:'column', color:'#666'}}><div>Webhook triggered @ {hook.target} at {getDateStr(hook.triggeredAt) + ' at ' + getTimeStr(hook.triggeredAt)} with payload:</div><pre>{JSON.stringify(hook.payload, null, 2)}</pre></Box>)}
      </Box>
    </Paper>}
  </Box>
  </Box>
  </Box>)
};

export default AgentRun;

//----

const getDateStr = (timestamp) => new Date(timestamp.seconds * 1000).toDateString()
const getTimeStr = (timestamp) => new Date(timestamp.seconds * 1000).toLocaleTimeString()

const shortenString = (text) => {
  const shortened = text.slice(0,180)
  const needsShortening = (shortened.length < text.length)
  const toReturn = needsShortening ? (shortened + "...") : shortened;
  return [toReturn, needsShortening];
}

const previewString = (text) => {
  return shortenString(text)[0];
}

const RunStepBox = ({run, step, isLastItem}) => {
  const { account } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  let item;
  let icon;
  let iconColor;
  const isRunning = (step.state === RUN_STATE.TASK_RUNNING)
  const isAtHuman = (step.state === RUN_STATE.REQUESTED_HUMAN) || (step.state === RUN_STATE.SERVED_TO_HUMAN)
  const forceExpanded = (isRunning || isAtHuman)
  const [expanded, setExpanded] = useState(forceExpanded);
  
console.log(`${step.state} forceExpanded ${forceExpanded} expanded ${expanded}`)
  if (isRunning) {
    item = isLastItem ? <RunStepRunning step={step} /> : <Box sx={{paddingTop:"4px"}}>Started</Box>; //not shown,filtered above
  } else if (step.state === RUN_STATE.TASK_DONE) {
    item = (step.taskDesc || step.result) ? <RunStepDone step={step} /> : null;
    iconColor = "approve";
    icon = <DoneIcon fontSize="inherit" sx={{fontSize:'14px'}} />
  } else if (isAtHuman) {
    item = isLastItem ? (step.subState === HUMAN_REVIEW_SUB_STATES.MULTI_SELECT_FAN_OUT ? <RunStepForHumanSelection accountId={account.id} accountPath={account.path} user={user} step={step} run={run} /> : <RunStepForHuman accountId={account.id} accountPath={account.path} user={user} step={step} run={run} />) : ((step.state === RUN_STATE.REQUESTED_HUMAN) ? "Human approval requested" : "Result sent to human") //not shown,filtered above
  } else if ((step.state === RUN_STATE.APPROVED) || (step.state === RUN_STATE.REJECTED) || (step.state === RUN_STATE.ARCHIVED)) {
    item = (step.actionValues && step.actionValues.filter(val => !val.hide).length) ? <RunStepHumanReacted step={step} /> : null
    iconColor = (step.state === RUN_STATE.APPROVED) ? "approve" : ((step.state === RUN_STATE.REJECTED) ? "reject" : "archive");
    icon = ((step.state === RUN_STATE.APPROVED) || (step.state === RUN_STATE.ARCHIVED)) ? <DoneIcon fontSize="inherit" sx={{fontSize:'14px'}} /> : ((step.state === RUN_STATE.REJECTED) ? <BlockIcon fontSize="inherit" sx={{fontSize:'14px'}} /> : "");
  }
  const itemBox = (
    <Box sx={{display:'flex', flexDirection:'column', paddingTop:"4px"}}>
      <Box sx={{display:'flex', justifyContent:'space-between'}}>
        <Box sx={{display:'flex', flexDirection:'row', gap:"12px", alignItems:'center'}}>
          {(step.taskName || step.taskId) && <Typography sx={{fontWeight: 'medium'}}>{step.taskName || step.taskId}</Typography>}
          {(mapRunStateGroup(step.state) === RUN_STATE_GROUP.DONE) ? <ReactedSticker step={step} /> : ""}
          {item && !forceExpanded && expanded && <Button size='small' sx={{height:'24px'}} onClick={() => setExpanded(false)}><ExpandLessOutlinedIcon /></Button>}
          {item && !forceExpanded && !expanded && <Button size='small' sx={{height:'24px'}} onClick={() => setExpanded(true)}><ExpandMoreOutlinedIcon /></Button>}
        </Box>
        <Typography variant="subtitle2" sx={{color:'#aaa', fontSize:'12px', paddingLeft:'20px', textAlign:'right'}}>{getDateStr(step.timestamp) + ' at ' + getTimeStr(step.timestamp)}</Typography>
      </Box>
      {expanded && item && (
        <Box sx={{display:'flex', flexDirection:'column'}}>
          {step.taskDesc && <Typography variant="caption" sx={{marginTop:"10px"}}>{step.taskDesc}</Typography>}
          <Box sx={{marginTop:'14px', marginBottom:'10px'}}>
            {item}
          </Box>
        </Box>)}
    </Box>)
    return <TimelineItem key={step.docId}><TimelineSeparator>{icon ? <TimelineDot color={iconColor} sx={{padding:'2px'}}>{icon}</TimelineDot> : <TimelineDot variant={isLastItem ? "outlined" : "filled"} sx={{width:'20px', height:'20px'}} />}{((!isLastItem) || step.state === RUN_STATE.TASK_DONE) && <TimelineConnector />}</TimelineSeparator><TimelineContent>{itemBox}</TimelineContent></TimelineItem>
}

const RunStepRunning = ({step}) => {
  return (<Box sx={{display:'flex', flexDirection:'column'}}>
    <Dots/>
  </Box>)
}
const RunStepDone = ({step}) => {
  const [expanded, setExpanded] = useState(false);
  return (<Box sx={{display:'flex', flexDirection:'column', paddingTop:"4px", gap: '4px'}}>
    {step.result && (step.type === "openai_conversation" ? <Conversation messages={step.result} /> : (Array.isArray(step.result) ? step.result : [step.result]).map((res, i) =>
    <Box key={i} sx={{padding:'20px', background:'#f8f8f8', borderRadius:'8px'}}>
      <Box sx={{paddingTop:"4px", whiteSpace:'pre-line'}}>{expanded ? res : previewString(res)}{shortenString(res)[1] ? <Typography sx={{display: 'inline', paddingLeft:'8px', fontSize:'14px', color:'#ccc', cursor:'pointer'}} onClick={()=>setExpanded(val => !val)}>{expanded ? 'Show less' : 'Show more'}</Typography> : ""}</Box>
    </Box>))}
  </Box>)
}

const ReactedSticker = ({step}) => {
  let bg = '#e5b603';
  let text = 'Archived';
  if (step.state == RUN_STATE.APPROVED) {
    bg = '#6DA34D'
    text = 'Approved'
  } else if (step.state == RUN_STATE.REJECTED) {
    bg = '#AF3800'
    text = 'Rejected'
  }
  return <Tooltip title={step.userEmailResponded ? `by ${step.userEmailResponded}` : ""}><Box sx={{}}><Typography variant='subtitle1' sx={{ fontSize:'12px', background:bg, color: '#FFF', borderRadius:'4px', paddingLeft: '6px', paddingRight: '6px'}}>{text}</Typography></Box></Tooltip>
}

const Conversation = ({messages}) => {
  return !Array.isArray(messages) ? "" : <Box sx={{display:'flex', flexDirection:'column', gap:'14px', padding:'0px'}}>{messages.sort((a,b)=>a.created_at - b.created_at).map((msg) => <ConversationMessage key={msg.id} message={msg} />)}</Box>
}
const ConversationMessage = ({message}) => {
  return <Box sx={{display:'flex', flexDirection:'column', alignSelf:'start', gap:'10px'}}>
      {message.run_obj && <OpenAiRunSteps runObj={message.run_obj} />}
    <Box sx={{display:'flex', flexDirection:'column', alignSelf:'start', gap:'10px', padding:'20px', background:'#CFDBD5', borderRadius:'8px'}}>
      <Box sx={{display:'flex',padding:'0px'}}>
        <Typography variant='subtitle1' sx={{ fontSize:'12px', fontWeight:'500', background:'#F1F1F1', color: '#a1a1a1', borderRadius:'4px', px: '6px'}}>{message.role}</Typography>
      </Box>
      {message.content.map((contentItem, i) => contentItem.type === "text" ? <Typography key={i} variant='body2' sx={{ px:'0px'}}>{openaiMsgText(contentItem.text, message.run_obj)}</Typography> : contentItem.type)}
    </Box>
  </Box>
}

const OpenAiRunSteps = ({runObj}) => {
  return runObj.step_objs ? (
    <Box sx={{display:'flex', flexDirection:'column', marginLeft:'40px', padding:'20px', background:'#cbd0d3', borderRadius:'8px'}}>
      <Box sx={{display:'flex',padding:'0px'}}>
        <Typography variant='subtitle1' sx={{ fontSize:'12px', fontWeight:'500', background:'#F1F1F1', color: '#a1a1a1', borderRadius:'4px', px: '6px'}}>Tool Call</Typography>
      </Box>
      {runObj.step_objs.filter(step => step.type === "tool_calls" && step.status === "completed").map(step => <OpenAiToolCall key={step.id} step={step} />)}
    </Box>
  ) : "";
}
const OpenAiToolCall = ({step}) => {
  return <Box sx={{display:'flex', flexDirection:'column'}}>
    {step.step_details.tool_calls.map((toolCall) => {
      return <Box key={toolCall.id}>
        {(toolCall.type === "file_search" && toolCall.file_search.results) && toolCall.file_search.results.map(result => <OpenAiFileSearch result={result} />)}
      </Box>
    })}
  </Box>
}
const OpenAiFileSearch = ({result}) => {
  const [expanded, setExpanded] = useState(false)

    return (
      <Box sx={{display:'flex', flexDirection:'column', gap:'8px',py:'10px'}}>
        <Box sx={{display:'flex', gap:'8px'}}>
          <Box sx={{display:'flex', alignItems:'center', gap:'8px', background:'#555', borderRadius:'4px', px: '6px', py:'2px'}}>
            <InsertDriveFileIcon sx={{ fontSize: 16, color:'#f8f8f8' }} />
            <Typography variant='subtitle1' sx={{ fontSize:'12px', fontWeight:'500', color: '#f8f8f8'}}>{result.file_name}</Typography>
          </Box>
          <Tooltip title="File search result score">
            <Typography variant='subtitle1' sx={{ fontSize:'12px', fontWeight:'700', color: interpolateColor(result.score), border:('1px solid '+ interpolateColor(result.score)), borderRadius:'4px', px: '6px', py:'2px'}}>{result.score.toFixed(2)}</Typography>
          </Tooltip>
          {expanded && <Button size='small' sx={{height:'24px'}} onClick={() => setExpanded(false)}><ExpandLessOutlinedIcon /></Button>}
          {!expanded && <Button size='small' sx={{height:'24px'}} onClick={() => setExpanded(true)}><ExpandMoreOutlinedIcon /></Button>}
        </Box>
        {!expanded ? "" : result.content.map((contentItem, i) => contentItem.type === "text" ? <Typography key={i} variant='body2' sx={{background:'#dfdfdf', p:'12px'}}>{contentItem.text}</Typography> : contentItem.type)}
      </Box>
    )
}

const openaiMsgText = (msgText, runObj) => {
    const { annotations } = msgText;
    const citations = [];

    let index = 1;
    for (let annotation of annotations) {
      msgText.value = msgText.value.replace(annotation.text, " [" + index + "]");
      if (annotation.type === "file_citation") {
        // const citedFile = await openai.files.retrieve(file_citation.file_id);
        // citations.push("[" + index + "]" + citedFile.filename);
        citations.push("[" + index + "]" + findFileName(annotation.file_citation.file_id, runObj));
      } else if (annotation.type === "file_path") {
        // const citedFile = await openai.files.retrieve(file_citation.file_id);
        // citations.push("[" + index + "]" + citedFile.filename);
        citations.push("[" + index + "]" + annotation.file_path);
      }
      index++;
    }
    return msgText.value + (citations.length ? "\n" : "") + citations.join("\n");
}

const findFileName = (fileId, runObj) => {
  /* if (runObj.step_objs) {
    const toolCallsDone = runObj.step_objs.filter(step => step.type === "tool_calls" && step.status === "completed" && step.step_details.tool_calls)
    const fileSearchesDone = toolCallsDone.map(step => step.step_details.tool_calls).filter(toolCall => toolCall.type === "file_search" && toolCall.file_search.results)
    const fileSearchResults = fileSearchesDone.map(toolCall => toolCall.file_search.results).find(result => result.file_id == fileId) //then return result.file_name
  }
  return ""; */

  if (runObj.step_objs) {
    // Filter for completed tool calls
    const toolCallsDone = runObj.step_objs.filter(step => 
      step.type === "tool_calls" && 
      step.status === "completed" && 
      step.step_details.tool_calls
    );

    // Map to get the file search results
    const fileSearchResults = toolCallsDone.flatMap(step => 
      step.step_details.tool_calls
        .filter(toolCall => toolCall.type === "file_search" && toolCall.file_search.results)
        .flatMap(toolCall => toolCall.file_search.results)
    );

    // Find the result that matches the fileId
    const foundResult = fileSearchResults.find(result => result.file_id == fileId);

    // Return the file name if found, otherwise return an empty string
    return foundResult ? foundResult.file_name : "?";
  }
  return "?";
}

const interpolateColor = (value) => {
  // Ensure the value is within the range [0.0, 1.0]
  value = Math.max(0, Math.min(1, value));

  // Define the start and end colors
  const startColor = { r: 0xAF, g: 0x38, b: 0x00 }; // #AF3800
  const endColor = { r: 0x6D, g: 0xA3, b: 0x4D };   // #6DA34D

  // Interpolate each color component
  const r = Math.round(startColor.r + (endColor.r - startColor.r) * value);
  const g = Math.round(startColor.g + (endColor.g - startColor.g) * value);
  const b = Math.round(startColor.b + (endColor.b - startColor.b) * value);

  // Convert the color components to a hex string
  const hex = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;

  return hex;
}

const RunStepForHuman = ({accountId, accountPath, user, step, run}) => {
  const [humanReactTrigger, setHumanReactTrigger] = useState(TRIGGER.IDLE);
  const [editedActionValues, setEditedActionValues] = useState(step.actionValues);
  const runDocId = run.docId
  const role = (!user || !user.access || !accountPath) ? null : user.access[accountPath];
  const isDevOrAdmin = !!role && ['dev', 'admin'].includes(role);
  
  useEffect(() => {
    if (humanReactTrigger === TRIGGER.IDLE) return () => { };
    const stateAfterReaction = (humanReactTrigger === TRIGGER.APPROVE) ? RUN_STATE.APPROVED : ((humanReactTrigger === TRIGGER.REJECT) ? RUN_STATE.REJECTED : RUN_STATE.ARCHIVED)
    const actionValuesWithEditedFlag = editedActionValues.map(val => {
      const oldValue = step.actionValues.find(el => el.id === val.id)
      const valHasChanged = oldValue && (oldValue.text !== val.text)
      return {...val, ...(step.allowEditing && {wasEdited: valHasChanged})};
    })
    const subscription = Data.getInstance().sendHumanReaction(user.id, user.email, accountId, runDocId, step.taskId, step.taskName, actionValuesWithEditedFlag, stateAfterReaction)
      .subscribe((x) => {
          setHumanReactTrigger(TRIGGER.IDLE);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, runDocId, humanReactTrigger]);

  const onValueEdit = (valId, newText) => {
    setEditedActionValues(values => values.map((val) => (val.id == valId) ? {...val, text: newText} : val))
  }

  return (
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'end', padding:'20px', gap: '18px', background:'#E7E5DF', borderRadius:'8px', boxShadow: 3}}>
      {editedActionValues && editedActionValues.filter(val => !val.hide).map((value) => <RunStepActionValue value={value} allowEditing={step.allowEditing} onValueEdit={onValueEdit} />)}
      <Box sx={{display:'flex', gap:'20px'}}>
        {step.state===RUN_STATE.REQUESTED_HUMAN && <Button variant="contained" color="approve" sx={{borderRadius: '8px'}} disabled={!run.agent.webhook || (humanReactTrigger !== TRIGGER.IDLE)} onClick={()=>setHumanReactTrigger(TRIGGER.APPROVE)} startIcon={(humanReactTrigger === TRIGGER.APPROVE) ? <CircularProgress color="approve" size={24} /> : <DoneIcon />}>Approve</Button>}
        {step.state===RUN_STATE.REQUESTED_HUMAN && <Button variant="contained" color="reject" sx={{borderRadius: '8px'}} disabled={!run.agent.webhook || (humanReactTrigger !== TRIGGER.IDLE)} onClick={()=>setHumanReactTrigger(TRIGGER.REJECT)} startIcon={(humanReactTrigger === TRIGGER.REJECT) ? <CircularProgress color="reject" size={24} /> : <BlockIcon />}>Reject</Button>}
        {step.state===RUN_STATE.SERVED_TO_HUMAN && <Button variant="contained" color="secondary" sx={{borderRadius: '8px'}} disabled={(humanReactTrigger !== TRIGGER.IDLE)} onClick={()=>setHumanReactTrigger(TRIGGER.ARCHIVE)} startIcon={(humanReactTrigger === TRIGGER.ARCHIVE) ? <CircularProgress size={24} /> : <DoneIcon />}>Archive</Button>}
      </Box>
      {step.state===RUN_STATE.REQUESTED_HUMAN && !run.agent.webhook && <Typography sx={{color:'red', fontSize:'14px'}}>{isDevOrAdmin ? "HTTP endpoint missing! Please add it for your agent in the settings (⚙️ icon above)." : "URL missing! Please ask your developer or admin to complete setup."}</Typography>}
    </Box>)
}

const RunStepActionValue = ({value, allowEditing, onValueEdit}) => {
  const [editing, setEditing] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <Box key={value.id} sx={{display:'flex', flexDirection:'column', gap: '8px', width:'100%'}}>
        {(value.label || value.wasEdited) && (
          <Box sx={{display:'flex', flexDirection:'row', gap: '8px'}}>
            <Box sx={{paddingLeft:'10px', typography: 'caption'}}><Tooltip title={`id: ${value.id}`} placement="right">{value.label}</Tooltip></Box>
            {value.wasEdited && <Box sx={{typography: 'caption'}}><i>edited</i></Box>}
          </Box>)}
        {(value.text != null) && !editing && <Box sx={{background:'#f8f8f8', borderRadius:'4px', padding:'10px', whiteSpace:'pre-line'}}>{(expanded || allowEditing) ? (value.text || <>&nbsp;</>) : (previewString(value.text) || <>&nbsp;</>)}{(!allowEditing && shortenString(value.text)[1]) ? <Typography sx={{display: 'inline', paddingLeft:'8px', fontSize:'14px', color:'#ccc', cursor:'pointer'}} onClick={()=>setExpanded(val => !val)}>{expanded ? 'Show less' : 'Show more'}</Typography> : ""}</Box>}
        {(value.text != null) && editing && <TextField
          fullWidth
          multiline
          maxRows={8}
          sx={{background:'#f8f8f8', whiteSpace:'pre-line'}}
          value={value.text}
          onChange={(event) => {
            onValueEdit(value.id, event.target.value);
          }}
        />}
        {allowEditing && <Box sx={{display:'flex'}}>
          {!editing && <Button variant="outlined" color="primary" onClick={()=>setEditing(true)} startIcon={<EditIcon />}>Edit</Button>}
          {editing && <Button variant="contained" color="primary" onClick={()=>setEditing(false)} startIcon={<DoneIcon />}>Done</Button>}
        </Box>}
      </Box>
  )
}

const RunStepForHumanSelection = ({accountId, accountPath, user, step, run}) => {
  const [humanReactTrigger, setHumanReactTrigger] = useState(TRIGGER.IDLE);
  const [selectedValues, setSelectedValues] = useState([])
  const selectedAny = selectedValues.length > 0
  const runDocId = run.docId
  const role = (!user || !user.access || !accountPath) ? null : user.access[accountPath];
  const isDevOrAdmin = !!role && ['dev', 'admin'].includes(role);
  
  useEffect(() => {
    if (humanReactTrigger === TRIGGER.IDLE) return () => { };
    const stateAfterReaction = (humanReactTrigger === TRIGGER.APPROVE) ? RUN_STATE.APPROVED : RUN_STATE.REJECTED
    const subscription = Data.getInstance().sendHumanReaction(user.id, user.email, accountId, runDocId, step.taskId, step.taskName, selectedValues, stateAfterReaction, HUMAN_REVIEW_SUB_STATES.MULTI_SELECT_FAN_OUT)
      .subscribe((x) => {
          setHumanReactTrigger(TRIGGER.IDLE);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, runDocId, humanReactTrigger]);

  const onValueChange = (event) => {
    setSelectedValues((state) => {
      const newSelected = [...state.filter((val) => val.id !== event.target.name)]
      if (event.target.checked) {
        newSelected.push(step.actionValues.find((v) => v.id === event.target.name))
      }
      return newSelected
    })
  }

  return (<Box sx={{display:'flex', flexDirection:'column', gap: '14px', paddingTop:"4px"}}>
    {step.taskDesc && <Box sx={{color:'#aaa', fontSize:'14px'}}>{step.taskDesc}</Box>}
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'start', padding:'20px', gap: '18px', background:'#E7E5DF', borderRadius:'8px', boxShadow: 2}}>
      <FormGroup sx={{gap:'20px'}}>
      {step.actionValues && step.actionValues.map((value) => <Box key={value.id} sx={{display:'flex', flexDirection:'column', gap: '8px', width:'100%'}}>

        {value.label && <Box sx={{paddingLeft:'10px', typography: 'subtitle2', fontFamily: 'Monospace'}}><Tooltip title={`id: ${value.id}`} placement="right">{value.label}</Tooltip></Box>}
        <FormControlLabel control={<Checkbox checked={selectedValues.findIndex((v) => v.id === value.id)>=0} onChange={onValueChange} name={value.id} />} label={value.text} />
      </Box>)}
      </FormGroup>
      <Box sx={{display:'flex', gap:'20px', alignSelf:'stretch', justifyContent:'end'}}>
        <Button variant="contained" color='approve' sx={{borderRadius: '8px'}} disabled={!selectedAny || !run.agent.webhook || (humanReactTrigger !== TRIGGER.IDLE)} onClick={()=>setHumanReactTrigger(TRIGGER.APPROVE)} startIcon={(humanReactTrigger === TRIGGER.APPROVE) ? <CircularProgress color="approve" size={24} /> : <DoneIcon />}>Confirm</Button>
        <Button variant="outlined" color="reject" sx={{borderRadius: '8px'}} disabled={!run.agent.webhook || (humanReactTrigger !== TRIGGER.IDLE)} onClick={()=>setHumanReactTrigger(TRIGGER.REJECT)} startIcon={(humanReactTrigger === TRIGGER.REJECT) ? <CircularProgress color="reject" size={24} /> : <BlockIcon />}>Reject</Button>
      </Box>
      {step.state===RUN_STATE.REQUESTED_HUMAN && !run.agent.webhook && <Typography sx={{color:'red', fontSize:'14px'}}>{isDevOrAdmin ? "HTTP endpoint missing! Please add it for your agent in the settings (⚙️ icon above)." : "URL missing! Please ask your developer or admin to complete setup."}</Typography>}
    </Box>
  </Box>)
}

//'archived' might be done via API as well actually
const RunStepHumanReacted = ({step}) => {
  return (<Box sx={{display:'flex', flexDirection:'column', alignItems:'end', padding:'20px', gap: '18px', background:'#E7E5DF', borderRadius:'8px', boxShadow: 3}}>
    {/* <Box sx={{display:'flex', paddingTop:"4px", justifyContent:'space-between'}}>
      {(step.state === RUN_STATE.REJECTED) && <Typography sx={{color:'red', fontSize:'14px', fontWeight: 'medium'}}>{`Rejected by ${step.userEmailResponded}`}</Typography>}
      {(step.state === RUN_STATE.APPROVED) && <Typography sx={{color:'green', fontSize:'14px', fontWeight: 'medium'}}>{`Approved by ${step.userEmailResponded}`}</Typography>}
      {(step.state === RUN_STATE.ARCHIVED) && <Typography sx={{fontSize:'14px', fontWeight: 'medium'}}>{`Archived${step.userEmailResponded ? ` by ${step.userEmailResponded}` : ""}`}</Typography>}
      <Typography sx={{fontSize:'14px', color:'#ccc', textAlign:'right'}}>{getDateStr(step.timestamp) + ' at ' + getTimeStr(step.timestamp)}</Typography>
    </Box> */}
    {step.actionValues && step.actionValues.filter(val => !val.hide).map((value) => <RunStepActionValue key={value.id} value={value} allowEditing={false} /> )}
  </Box>)
}

const DropDownMenu = ({accountId, user, runDocId}) => {
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [archiveTrigger, setArchiveTrigger] = useState(false);
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleArchive = () => {
    setArchiveTrigger(true);
  };
  const handleDelete = () => {
    setDeleteTrigger(true);
  }
  
  useEffect(() => {
    if (!archiveTrigger) return () => { };
    const subscription = Data.getInstance().sendHumanReaction(user.id, user.email, accountId, runDocId, null, null, null, RUN_STATE.ARCHIVED)
      .subscribe((x) => {
        setArchiveTrigger(false);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, runDocId, archiveTrigger]);
  
  useEffect(() => {
    if (!deleteTrigger) return () => { };
    Data.getInstance().deleteDoc(`Accounts/${accountId}/AgentRuns/${runDocId}`).then(() => {
      setDeleteTrigger(false)
    }).catch((error) => {
      console.error("Couldn't delete run", error)
      setDeleteTrigger(false)
    });
  }, [deleteTrigger]);

  return (
    <Box sx={{padding:'0px'}}>
      {deleteTrigger && <Typography variant='subtitle'>Deleting...</Typography>}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
          <MenuItem key={"archive"} onClick={handleArchive}>
            Archive
          </MenuItem>
          <MenuItem key={"delete"} onClick={handleDelete}>
            Delete
          </MenuItem>
      </Menu>
    </Box>
  );
}