import React, { useState, useEffect, createContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { CardContent, Typography, Grid, Card, Box, TextField, Button, CircularProgress, LinearProgress, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { catchError } from 'rxjs/operators';
import Data from './Data';

// FirebaseManager.getInstance().init();

const AddFromTemplate = ({ user }) => {
  const { accountId, templateId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState();
  const [newAgent, setNewAgent] = useState();
  const [createTrigger, setCreateTrigger] = useState(false);
  const [err, setError] = useState(false);
  const deployUrl = (location.state?.deployUrl || '') + (newAgent?.endpointPath || '')
  console.log(`lcoationState ${JSON.stringify(location.state)} deployUrl ${deployUrl}`)

  const checkAgent = (agent) => {
    console.log("checkAgent " + (agent ? JSON.stringify(agent) : 'noagent'))
    if (!agent.config) return true;
    return Object.values(agent.config).every((val) => val && val.length)
  }

  const inputValid = newAgent && checkAgent(newAgent)

  const getConfig = (agent, index) => {
    if (!agent.config || Object.entries(agent.config).length <= index) return null;
    return Object.entries(agent.config)[index];
  }

  useEffect(() => {
    console.log('loadTemplate ' + templateId);
    setLoading(true);
    setError(null);
    const subscription = Data.getInstance().fetchAgentTemplate(templateId)
      .pipe(catchError(() => {
        setLoading(false);
        setError('There was an error loading the template.')
      }))
      .subscribe(
        (template) => {
          setLoading(false);
          console.log("loaded teplate:" + JSON.stringify(template))
          setTemplate(template);
          setNewAgent({ ...template });
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    console.log('createTrigger ' + createTrigger);
    if (createTrigger && inputValid) {
      const subscription = Data.getInstance().createAgentConnector(accountId, newAgent.id, newAgent.name, deployUrl, (newAgent.inputs && newAgent.inputs.length) ? newAgent.inputs[0] : null, getConfig(newAgent, 0), getConfig(newAgent, 1), getConfig(newAgent, 2))
        .pipe(catchError(() => {
          setCreateTrigger(false);
          setError('There was an error creating the connector.')
          console.log(err.message);
        }))
        .subscribe((data) => {
          console.log(data);
          navigate(`/accounts/${accountId}`, { replace: true })
        });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [createTrigger]);

  const onValueEdit = (key, val) => {
    setNewAgent({ ...newAgent, config: { ...newAgent.config, [key]: val } })
  }
  console.log("newAgent " + JSON.stringify(newAgent))
  if (loading) return <LinearProgress color="secondary" />
  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: { xs: '40px', lg: '40px' },
      paddingBottom: '60px',
      background: '#F1F1F1',
      overflowY: 'auto',
      overflowX: 'hidden',
      '::-webkit-scrollbar-track': { background: "#00000000", }, '::-webkit-scrollbar-thumb': { background: '#ccc', borderRadius: 8, }, '::-webkit-scrollbar': { width: '8px' },
      alignItems: 'center',
      textAlign: 'left',
      fontSize: '14px',
    }}>
      <Box sx={{
        width: '90%',
        maxWidth: '940px',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        alignItems: 'center',
      }}>
        <Typography variant='h4' sx={{ fontWeight: '600', marginBottom: '32px' }}>Connect your agent</Typography>
        {newAgent && <Card sx={{
          width: '100%',
          borderRadius: '8px',
          background: '#fafafa',
          px: { xs: '20px', lg: '80px' },
          py: '24px',
        }}>
          <CardContent sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'left',
          }}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, alignItems: 'start', marginBottom: '8px' }}><Typography typography={"subtitle2"} sx={{ width: '120px', flexShrink: '0' }}>Name:</Typography><Typography sx={{ marginLeft: { xs: '0px', lg: '12px' }, fontFamily: 'Monospace' }}>{template.name}</Typography></Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, alignItems: 'start', marginBottom: '8px' }}><Typography typography={"subtitle2"} sx={{ width: '120px', flexShrink: '0' }}>Id:</Typography><Typography sx={{ marginLeft: { xs: '0px', lg: '12px' }, fontFamily: 'Monospace' }}>{template.id}</Typography></Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, alignItems: 'start', marginBottom: '8px' }}><Typography typography={"subtitle2"} sx={{ width: '120px', flexShrink: '0' }}>Deployed at:</Typography><Tooltip title={deployUrl}><Typography sx={{ marginLeft: { xs: '0px', lg: '12px' }, fontFamily: 'Monospace', overflowX: 'hidden', textWrap: 'nowrap' }}>{deployUrl.slice(0, 30) + "..."}</Typography></Tooltip></Box>
            {template.inputs && template.inputs.length && <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, alignItems: 'start' }}><Typography typography={"subtitle2"} sx={{ width: '120px', flexShrink: '0' }}>Inputs required to trigger run:</Typography><Typography sx={{ marginLeft: { xs: '0px', lg: '12px' }, fontFamily: 'Monospace' }}>{template.inputs.join(',')}</Typography></Box>}
            {newAgent.config && Object.keys(newAgent.config).length && <Typography variant='h6' sx={{ marginTop: '20px', marginBottom: '12px', textAlign: 'center' }}>Configure your agent:</Typography>}
            {newAgent.config && Object.keys(newAgent.config).length && Object.keys(newAgent.config).map((key) => {
              const value = newAgent.config[key]
              return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'left', marginBottom: '16px' }}>
                <Typography typography={"subtitle2"}>{key}:</Typography>
                {newAgent.configLabels && newAgent.configLabels[key] && <Typography typography={"caption"} sx={{ color: '#888', marginBottom:'8px' }}>{newAgent.configLabels[key] || ''}</Typography>}
                <TextField
                  fullWidth
                  multiline
                  maxRows={8}
                  sx={{ background: '#f8f8f8', whiteSpace: 'pre-line' }}
                  disabled={createTrigger}
                  value={value}
                  onChange={(event) => {
                    onValueEdit(key, event.target.value);
                  }}
                /></Box>
            })}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Button
                variant="contained"
                sx={{ width: '160px', marginTop: '24px' }}
                disabled={!inputValid || err || createTrigger}
                startIcon={createTrigger ? <CircularProgress size={24} /> : <AddCircleOutlineIcon />}
                onClick={() => {
                  setCreateTrigger(true)
                }}>Connect</Button>
              <Typography typography={"caption"} sx={{ marginTop: '24px', color: '#aaa' }}>You can change these values anytime in your settings.</Typography>
            </Box>
          </CardContent>
        </Card>}

        {err && <Box sx={{ color: 'red', padding: '4px' }}>{err}</Box>}

      </Box>
    </Box>
  );
};

export default AddFromTemplate;
