import React, {useState, useEffect, createContext} from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { CardContent, Typography, Grid, Card, Box, TextField, Button, CircularProgress } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Data from './Data';
import {Observable, of, from, throwError} from 'rxjs';
import {
  map,
  tap,
  catchError,
  mergeMap,
} from 'rxjs/operators';
import FirebaseManager from './FirebaseManager';

// FirebaseManager.getInstance().init();

const CreateAccount = ({user}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [input, setInput] = useState("");
  const [createTrigger, setCreateTrigger] = useState(false);
  const [err, setErr] = useState(false);
  const [createdAccApp, setCreatedAccApp] = useState();
  // const usersEmailVerified = user.emailVerified //deactivated so that template -> sign up -> create org -> add template flow is not disrupted
  const usersEmailVerified = true;

  useEffect(() => {
    console.log('createTrigger ' + createTrigger);
    if (!createTrigger || !input.length) {
      return () => { };
    }
    
    function readFromLocalStorage(key) {
      try {
        const jsonString = localStorage.getItem(key);
        if (jsonString) {
          return JSON.parse(jsonString);
        }
        return null;
      } catch (error) {
        console.error("Error reading from local storage", error);
        return null;
      }
    }
    
    const subscription = Data.getInstance().createAccountForUser(input)
      .pipe(
        mergeMap(data => {
          const savedForm = readFromLocalStorage(Data.LOCAL_STORAGE_FORM_KEY)
          return !savedForm ? of(data) : Data.getInstance().addForm(data.account, savedForm)
            .pipe(
              tap(newId => {
                localStorage.removeItem(Data.LOCAL_STORAGE_FORM_KEY)
                console.log("Saved precreated form "+newId)
                FirebaseManager.trackEvent("createNewForm")
              }),
              map(newId => data),
              catchError(() => {
                console.error(err.message);
                return of(data)
              }),
            )
        })
      )
      .subscribe({
        next: (data) => {
          console.log(data);
          setCreatedAccApp([data.account])
          FirebaseManager.trackEvent("createAccount")

          console.log('CreateAcc navigating to ' + `/accounts/${data.account}${location.state?.fromPath || ''}`);
          navigate(`/accounts/${data.account}${(location.state?.fromPath && location.state?.fromPath.startsWith('/add-agent-from-template')) ? location.state?.fromPath : ''}`, {replace: true, state: {deployUrl: location.state?.deployUrl}})
        },
        error: (err) => console.log(err.message)
      });
    return () => {
      if (subscription)
        subscription.unsubscribe();
    };
  }, [createTrigger]);

  /* useEffect(() => {
    console.log(`createdApp ${createdAccApp} user ${user.apps ? user.apps.map((el) => el.id) : "noApps"}`);
    if (!createdAccApp || !user.apps || !user.apps.length || !user.apps.findIndex((el) => el.id !== createdAccApp[1]) < 0) return;
    navigate("/", {replace: true})
  }, [createdAccApp, user]); */

  useEffect(() => {
    const regex = /^[a-zA-Z0-9](?:[a-zA-Z0-9_-]*[a-zA-Z0-9])?$/;
    setErr(input.length > 0 && (input.length > 40 || !regex.test(input)));
  }, [input]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!(!input.length || err || createTrigger)) {
        setCreateTrigger(true)
      }
    }
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '80px',
      paddingLeft: '50px',
      paddingRight: '50px',
      paddingBottom: '40px',
      textAlign: 'center',
    }}>
      <Box sx={{
        maxWidth: '600px',
        fontSize: '14px',
      }}>
      <Typography variant="h5" sx={{ marginBottom: '44px', fontWeight: '600' }}>Set up a new organization</Typography>
        <Card sx={{
          height: '180px',
          width: '280px',
          cursor: 'pointer',
          marginBottom: '24px',
        }}>
          <CardContent sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
          }}>
          <TextField id="input-app-name" label="Acme org" variant="standard" sx={{marginBottom: '24px'}} disabled={!usersEmailVerified} value={input} onChange={(ev) => setInput(ev.target.value)} onKeyDown={handleKeyDown} />
            <Button
              variant="contained"
              sx={{borderRadius:'8px'}}
              disabled={!input.length || err || createTrigger || !usersEmailVerified}
              startIcon={createTrigger ? <CircularProgress size={24} /> : <AddCircleOutlineIcon/>}
              onClick={() => {
                setCreateTrigger(true)
              }}>Create organization</Button>
              {err && <Box sx={{color:'red', padding:'4px'}}>Invalid name. Only allows a-z, A-Z, 0-9, hyphen, underscores.</Box>}
              {!usersEmailVerified && <Box sx={{color:'red', padding:'4px'}}>Please check your inbox and confirm your email address first.</Box>}
          </CardContent>
        </Card>
      </Box>
        <Box sx={{
          display:'flex',
          maxWidth: '600px',
          px:'20px',
          py:'12px',
          gap:'20px',
          background:'#CFDBD5',
          borderRadius: '8px'
        }}>
          <PersonAddIcon sx={{color: '#555'}} />
          <Typography variant='subtitle2' sx={{textAlign:'start'}}>To join an existing organization ask the owner/admin for an invite (pass your user ID: {user.id})</Typography>
        </Box>
    </Box>
  );
};

export default CreateAccount;
