import React, {useEffect, useState, useContext, createContext} from 'react';
import {Helmet} from "react-helmet";
import {BrowserRouter, Routes, Route, Navigate, useLocation, useMatch, useParams, useSearchParams} from 'react-router-dom';
import Login from './Login';
import Data from './Data';
import {Box, LinearProgress} from '@mui/material';
import Window from './Window';
import AccountFrame from './AccountFrame';
import CreateAccount from './CreateAccount';
import Inbox from './Inbox';
import Settings from './Settings';
import Agent from './Agent';
import AddFromTemplate from './AddFromTemplate';
import AgentRunPage from './AgentRunPage';
import FormsList from './FormsList';
import Review from './Review';
import FormUpdate from './FormUpdate';
import FormEditor from './FormEditor';
// import ConfirmEmail from "./ConfirmEmail";
import FirebaseManager from './FirebaseManager';


const LoginState = {Init: 'Init', UnconfirmedEmail: 'UnconfirmedEmail', LoggedIn: 'LoggedIn', LoggedOut: 'LoggedOut'};

function AppRoot() {
  const [user, setUser] = useState('init');
  const loginState = (user === 'init' ? LoginState.Init : (user ? (user.emailVerified === true ? LoginState.LoggedIn : LoginState.UnconfirmedEmail) : LoginState.LoggedOut))

  useEffect(() => {
    const subscription = Data.getInstance().streamLoginState()
        .subscribe(
        // (user) => this.setState({isSignedIn: !!user})
            (user) => {
              console.log('Logged in ' + (user ? JSON.stringify(user) : 'no user'));
              setUser(user);
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const trackPageView = (path) => {
    // console.log("trackPageviewPath " + path)
    const regexAccount = /^\/accounts\/([^\/]+)(\/|$)/i;
    const regexReviews = /^\/([^\/]+)\/([^\/]+)\/reviews\/([^\/]+)(\/|$)/i;
    const regexForms = /^\/([^\/]+)\/([^\/]+)\/forms\/([^\/]+)(\/|$)/i;
    path = path.replace(regexAccount, '/accounts/[accountId]$2')
    path = path.replace(regexReviews, '$1/$2/reviews/[reviewId]$4')
    const regexFormsNew = /^\/[^\/]+\/[^\/]+\/forms\/new\/?$/i;
    // console.log(`accregex ${regexFormsNew.test(path)} forPath ${path}`)
    if (!regexFormsNew.test(path)) {
      path = path.replace(regexForms, '$1/$2/forms/[formId]')
    }
    FirebaseManager.trackPageview(path)
  }

  const loginOk = (loginState === LoginState.LoggedIn || loginState === LoginState.UnconfirmedEmail) && user
  if (loginState === LoginState.Init) return <LinearProgress color="secondary" />;
  return (
    <BrowserRouter>
    <Routes>
            <Route path="/" element={<TrackedWindow user={user} trackPageView={trackPageView} />}>
              <Route path="create" element={<TryFormEditor />} />
              <Route path="signin" element={<LoginPage loginOk={loginOk} />} />
              <Route path="signedIn" element={<ContinueAfterSignUp loginOk={loginOk} user={user} />} />
              <Route path="logout" element={<Logout hasUser={!!user} />} />
              <Route index element={<RootDefault loginOk={loginOk} user={user} />} />
              <Route path="createAccount" element={<CreateAccountChecked loginOk={loginOk} user={user} />} />
              <Route path="add-agent-from-template/:templateId" element={<AddFromTemplateChecked loginOk={loginOk} user={user} />} />
            </Route>
            <Route path="accounts/:accountId/" element={<AccountFrameChecked loginOk={loginOk} user={user} trackPageView={trackPageView} />}>
                <Route index element={<Navigate to="reviews" replace />} />
                <Route path="runs/:runDocId" element={<AgentRunPage />} />
                <Route path="agents/:agentDocId" element={<Agent />} />
                <Route path="settings" element={<Settings />} />
                <Route path="add-agent-from-template/:templateId" element={<AddFromTemplate />} />
                <Route path="reviews" element={<ReviewsList />} />
                <Route path="reviews/:reviewId" element={<ReviewPage />} />
                <Route path="forms" element={<FormsPage />} />
                <Route path="forms/new" element={<NewFormEditor />} />
                <Route path="forms/:formId" element={<UpdateFormPage />} />
            </Route>
            <Route
              path="*"
              element={
                <Navigate to="/" replace />
            }
            />
        </Routes>
    </BrowserRouter>
  );
};

export default AppRoot;

const PageHeader = ({title}) => {
  return (<Helmet>
    <title>{title}</title>
    <meta property="og:title" content={title} />
  </Helmet>)
}

const TryFormEditor = ({}) => {
  return (<div>
    <PageHeader title={"gotoHuman - Create a free review form"} />
    <FormEditor />
  </div>)
}

const LoginPage = ({loginOk}) => {
  return (<div>
    <PageHeader title={"gotoHuman - Sign In"} />
    <Login loginOk={loginOk} />
  </div>)
}

const ReviewsList = ({}) => {
  return (<div>
    <PageHeader title={"gotoHuman - Your review requests"} />
    <Inbox />
  </div>)
}

const ReviewPage = ({}) => {
  return (<div>
    <PageHeader title={"gotoHuman - Review requested"} />
    <Review />
  </div>)
}

const FormsPage = ({}) => {
  return (<div>
    <PageHeader title={"gotoHuman - Your forms"} />
    <FormsList />
  </div>)
}

const NewFormEditor = ({}) => {
  return (<div>
    <PageHeader title={"gotoHuman - Create new review form"} />
    <FormEditor />
  </div>)
}

const UpdateFormPage = ({}) => {
  return (<div>
    <PageHeader title={"gotoHuman - Update your review form"} />
    <FormUpdate />
  </div>)
}

const RootDefault = ({loginOk, user}) => {
  if (!loginOk) return <Navigate to={"/signin"} replace />;
  console.log(`RootDefault user.accounts.length ${user.accounts?.length || 'none'}`)
  if (user.accounts && user.accounts.length >= 1) {
    return <Navigate to={user.accounts[0].path} replace />
  } else {
    return <Navigate to="/createAccount" replace />
  }
};

const CreateAccountChecked = ({loginOk, user}) => {
  const location = useLocation();
  console.log(`CreateAccountChecked`);
  if (!loginOk) return <Navigate to={"/signin"} replace />;
  console.log(`CreateAccountChecked user.accounts.length ${user.accounts?.length || 'none'} targetPath ${location.pathname}`)
  return (<div>
    <PageHeader title={"gotoHuman - Create an account for your organization"} />
    <CreateAccount user={user} />
  </div>)
};

const TrackedWindow = ({user, trackPageView}) => {
  const location = useLocation();  
  useEffect(() => {
    setTimeout(() => { //wait for Helmet
      trackPageView(location.pathname)
    }, 1)    
  }, [location.pathname]);
  return <Window user={user} />
}

const AccountFrameChecked = ({loginOk, user, trackPageView}) => {
  const location = useLocation();  
  useEffect(() => {
    setTimeout(() => { //wait for Helmet
      trackPageView(location.pathname)
    }, 1)  
  }, [location.pathname]);
  /* useEffect(() => {
    console.log(`Location changed ${location.pathname} ${location.state?.fromPath} ${location.state?.deployUrl}`);
  }, [location]); */
  console.log(`AccountFrameChecked`);
  if (!loginOk) return <Navigate to={"/signin"} replace state={{ fromPath: location.pathname }} />;
  console.log(`AccountFrameChecked user.accounts.length ${user.accounts?.length || 'none'} targetPath ${location.pathname}`)
  return <AccountFrame user={user} />
};

const AddFromTemplateChecked = ({loginOk, user}) => {
  const location = useLocation();
  console.log(`AddFromTemplateChecked`);
  let [searchParams, setSearchParams] = useSearchParams();
  const vercelDeployUrl = searchParams.get('deployment-url') || location.state?.deployUrl; //either first visit or coming from ContinueAfterSignUp 
  console.log(`AddFromTemplateChecked vercelDeployUrl ${vercelDeployUrl}`);
  const targetPath = location.pathname
  if (!loginOk) return <Navigate to={"/signin"} replace state={{ fromPath: targetPath, deployUrl: vercelDeployUrl }} />;
  console.log(`AddFromTemplateChecked user.accounts.length ${user.accounts?.length || 'none'} targetPath ${targetPath}`)
  if (user.accounts && user.accounts.length >= 1) {
    console.log("AddFromTemplateChecked nav to " + (user.accounts[0].path + targetPath))
    return <Navigate to={'/'+ user.accounts[0].path + targetPath} replace state={{deployUrl: vercelDeployUrl}}/>
  } else {
    return <Navigate {...{to:"/createAccount", replace: true, ...(targetPath ? { state: { fromPath: targetPath, deployUrl: vercelDeployUrl } } : {})}} />
  }
};

const ContinueAfterSignUp = ({loginOk, user}) => {
  const location = useLocation();
  console.log(`ContinueAfterSignUp`);
  const fromPath = location.state?.fromPath;
  const deployUrl = location.state?.deployUrl;
  if (!loginOk) return <Navigate to={"/signin"} replace />;
  console.log(`ContinueAfterSignUp user.accounts.length ${user.accounts?.length || 'none'} fromPath ${fromPath} deployUrl ${deployUrl}`)
  if (user.accounts && user.accounts.length >= 1) {
    return <Navigate to={fromPath || '/'} replace state={{deployUrl: deployUrl || null}} />
  } else {
    return <Navigate {...{to:"/createAccount", replace: true, ...(fromPath ? { state: { fromPath: fromPath, deployUrl: deployUrl } } : {})}} />
  }
};

const Logout = ({hasUser}) => {
  useEffect(() => {
    Data.getInstance().logout()
  }, [hasUser]);

  if (!hasUser) return <Navigate to={"/signin"} replace />;
  return <><PageHeader title={"gotoHuman - Logging out..."} /></>
};