import React, { useState, useEffect, useContext } from 'react';
import {Outlet, useParams, useNavigate, NavLink} from 'react-router-dom';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, LinearProgress, Rating, Link, Paper, ToggleButtonGroup, ToggleButton, Button, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DoneIcon from '@mui/icons-material/Done';
import Data from './Data';
import { AccountContext, UserContext } from './AccountFrame';
import { catchError } from 'rxjs';
import FormEditor from './FormEditor';

const FormUpdate = () => {
  const { account } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  const { formId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [form, setForm] = useState();
  
  useEffect(() => {
    setLoading(true);
    setError(null);
    const subscription = Data.getInstance().fetchForm({accPath: `Accounts/${account.id}`, formId: formId})
        .subscribe({
            next: (form) => {
              setLoading(false);
              console.log("FormEdit loaded ", form)
              console.log("FormEdit loaded ", JSON.stringify(form))
              setForm(form);
            },
            error: (err) => setError('There was an error loading the form.')
        });

    return () => {
      subscription.unsubscribe();
    };
  }, [account, formId]);

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      {loading && <LinearProgress color="secondary" />}
      {error && <Box sx={{color: 'red', padding:'10px', textAlign:'center'}}>{error}</Box>}
      {!loading && !form && <Box sx={{padding:'20px', textAlign:'center'}}><i>Form not found</i></Box>}
      {form && <FormEditor formToEdit={form} />}
    </Box>
  )
};

export default FormUpdate;